<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Online SMS Portal</h4>
      <ul class="nav nav-tabs mt-4">
        <li class="nav-item">
          <router-link
            :to="{ query: { tab: 'default' } }"
            :class="`nav-link ${show_tab == 'default' ? 'active' : ''}`"
            >Input Numbers</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :to="{ query: { tab: 'contacts' } }"
            :class="`nav-link ${show_tab == 'contacts' ? 'active' : ''}`"
            >Select From Phone Book</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            :to="{ query: { tab: 'file' } }"
            :class="`nav-link ${show_tab == 'file' ? 'active' : ''}`"
            >Import Excel</router-link
          >
        </li>
      </ul>
      <div class="tab-content">
        <div
          id="input-numbers"
          :class="`${show_tab == 'default' ? 'active' : ''} tab-pane`"
        >
          <div class="row">
            <div class="col-md-5 recipients-part">
              <label>recipients</label>
              <div
                class="form-group"
                v-for="(row, i) in recipients"
                :key="`recipient-${i}`"
              >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Recipient's Phone Number"
                    v-model="row.phone_number"
                  />
                  <div class="input-group-append" v-if="recipients.length > 1">
                    <span class="input-group-text" id="basic-addon2"
                      ><a
                        href="#"
                        @click.prevent="recipients.splice(i, 1)"
                        class="text-danger"
                        ><i class="lni-close"></i></a
                    ></span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <a
                  href="#"
                  @click.prevent="recipients.push({})"
                  class="btn btn-primary btn-xs"
                  ><i class="lni-plus mr-2"></i>Add recipient</a
                >
              </div>
            </div>
            <div class="col-md-7">
              <div class="form-group select-template">
                <div class="form-row">
                  <div class="col-3">
                    <label>Select Template</label>
                  </div>
                  <div class="col-9">
                    <select v-model="selected_template" class="form-control">
                      <option value="0">None Selected</option>
                      <option :value="template" v-for="(template, i) in templates" :key="`template-${i}`">{{ template.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Message</label>
                <textarea
                  placeholder="Type your message here"
                  class="form-control"
                  rows="10"
                  v-model="message"
                ></textarea>
                {{ messages_count }} messages, {{ message_length }} characters
              </div>
              <div class="alert alert-info mt-0 text-left">The opt-out option <strong>STOP*456*9*5#</strong> is included and mandatory in all messages.</div>
              <div class="form-group float-left">
                <input type="checkbox" class="mr-2" id="schedule-later" v-model="schedule_message">
                <label for="schedule-later">Schedule this message to be sent later</label>
                <datetime-picker 
                  v-if="schedule_message" 
                  placeholder="Select Date and Time"
                  :dayStr="['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']" 
                  :monthStr="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']"
                  :timeStr="['Ho', 'Mi', 'S']"
                  btnStr="Okay"
                  timeType="minute"
                  v-model="scheduled_time"
                  class="schedule-time"
                />
              </div>
              <a
                href="#"
                class="btn btn-primary btn-sm float-right"
                @click.prevent="sendMessage"
                ><i class="lni-envelope mr-2"></i>Send Message</a
              >
            </div>
          </div>
        </div>
        <div
          id="phone-book"
          :class="`${show_tab == 'contacts' ? 'active' : ''} tab-pane`"
        >
          <div class="row mt-4">
            <div class="col-md-5 recipients-part">
              <div class="form-group">
                <a
                  href="#"
                  @click.prevent="select_contacts = true"
                  class="btn btn-primary btn-sm"
                  ><i class="lni-users mr-2"></i>Select From Phone Book</a
                >
              </div>
              <template v-if="phone_book_imported">
                <label>recipients</label>
                <div
                  class="form-group"
                  v-for="(row, i) in recipients"
                  :key="`recipient-${i}`"
                >
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Recipient's username"
                      v-model="row.phone_number"
                    />
                  </div>
                </div>
              </template>
            </div>
            <div class="col-md-7" v-if="phone_book_imported">
              <div class="form-group select-template">
                <div class="form-row">
                  <div class="col-3">
                    <label>Select Template</label>
                  </div>
                  <div class="col-9">
                    <select v-model="selected_template" class="form-control">
                      <option value="0">None Selected</option>
                      <option :value="template" v-for="(template, i) in templates" :key="`template-${i}`">{{ template.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Message</label>
                <textarea
                  placeholder="Enter your message here"
                  class="form-control"
                  rows="10"
                  v-model="message"
                ></textarea>
                {{ messages_count }} messages, {{ message_length }} characters
              </div>
              <div class="alert alert-info mt-0 text-left">The opt-out option <strong>STOP*456*9*5#</strong> is included and mandatory in all messages.</div>
              <div class="form-group float-left">
                <input type="checkbox" class="mr-2" id="schedule-later" v-model="schedule_message">
                <label for="schedule-later">Schedule this message to be sent later</label>
                <datetime-picker 
                  v-if="schedule_message" 
                  placeholder="Select Date and Time"
                  :dayStr="['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']" 
                  :monthStr="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']"
                  :timeStr="['Ho', 'Mi', 'S']"
                  btnStr="Okay"
                  timeType="minute"
                  v-model="scheduled_time"
                  class="schedule-time"
                />
              </div>
              <a
                href="#"
                class="btn btn-primary btn-sm float-right"
                @click.prevent="sendMessage"
                ><i class="lni-envelope mr-2"></i>Send Message</a
              >
            </div>
          </div>
        </div>
        <div
          id="excel"
          :class="`tab-pane ${show_tab == 'file' ? 'active' : ''}`"
        >
          <div class="row mt-4">
            <div class="col-md-5 recipients-part">
              <template v-if="excel_file_imported">
                <label>recipients</label>
                <div
                  class="form-group"
                  v-for="(row, i) in recipients"
                  :key="`recipient-${i}`"
                >
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Recipient's username"
                      v-model="row.phone_number"
                    />
                  </div>
                </div>
              </template>
              <div class="form-group">
                <a
                  href="#"
                  @click.prevent="show_upload_excel = true"
                  class="btn btn-success btn-sm"
                  ><i class="lni-files mr-2"></i>Import Excel file</a
                >
              </div>
            </div>
            <div class="col-md-7" v-if="excel_file_imported">
              <div class="form-group select-template">
                <div class="form-row">
                  <div class="col-3">
                    <label>Select Template</label>
                  </div>
                  <div class="col-9">
                    <select v-model="selected_template" class="form-control">
                      <option value="0">None Selected</option>
                      <option :value="template" v-for="(template, i) in templates" :key="`template-${i}`">{{ template.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="alert alert-info">
                You may create programmable sms using your uploaded excel file
                and use the columns and as:
                <span
                  class="mr-2"
                  v-for="(column, i) in available_columns"
                  :key="`col-${i}`"
                  ><strong>@{{ column }}</strong></span
                >
              </div>
              <div class="form-group">
                <label>Message</label>
                <textarea
                  placeholder="Eg, hello @name, wlecome to tradesk"
                  class="form-control"
                  rows="10"
                  v-model="message"
                ></textarea>
                {{ messages_count }} messages, {{ message_length }} characters
              </div>
              <div class="alert alert-info mt-0 text-left">The opt-out option <strong>STOP*456*9*5#</strong> is included and mandatory in all messages.</div>
              <div class="form-group float-left">
                <input type="checkbox" class="mr-2" id="schedule-later" v-model="schedule_message">
                <label for="schedule-later">Schedule this message to be sent later</label>
                <datetime-picker 
                  v-if="schedule_message" 
                  placeholder="Select Date and Time"
                  :dayStr="['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']" 
                  :monthStr="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']"
                  :timeStr="['Ho', 'Mi', 'S']"
                  btnStr="Okay"
                  timeType="minute"
                  v-model="scheduled_time"
                  class="schedule-time"
                />
              </div>
              <a
                href="#"
                class="btn btn-primary btn-sm float-right"
                @click.prevent="sendExcelMessages"
                ><i class="lni-envelope mr-2"></i>Send Message</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal :show="show_modal" @close="show_modal = false">
      <template slot="header">recipients</template>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Phone Number</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, i) in response" :key="`row-${i}`">
            <td>{{ row.phone_number }}</td>
            <td>
              <span
                :class="
                  `badge badge-${
                    row.status == 'success' ? 'success' : 'danger'
                  }`
                "
                >{{ row.status }}</span
              >
            </td>
            <td>{{ row.description }}</td>
          </tr>
        </tbody>
      </table>
    </modal>
    <modal :show="show_upload_excel" @close="show_upload_excel = false">
      <template slot="header">Import Excel File</template>
      <div class="alert alert-info">
        Your file must contain a number column. All other columns will be
        available to include in your message content.
      </div>
      <form @submit.prevent="importFile">
        <div class="form-group">
          <label>Select File</label>
          <input
            type="file"
            ref="import_file"
            class="form-control"
            placeholder="Select File"
            required
          />
        </div>
        <div class="form-group text-right">
          <button class="btn btn-primary">
            <i class="lni-upload mr-2"></i>Upload
          </button>
        </div>
      </form>
    </modal>
    <modal @close="select_contacts = false" :show="select_contacts">
      <template slot="header">Select contact lists</template>
      <table class="table table-striped">
        <tbody>
          <tr v-for="(list, i) in contact_lists" :key="`contact-list-${i}`">
            <td>{{ list.name }}</td>
            <td align="right">
              <a
                href="#"
                class="btn btn-xs btn-primary"
                @click.prevent="selectList(list)"
                >Select</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetchContacts()
    this.fetchTemplates()
  },

  data() {
    return {
      recipients: [{}],
      message: "",
      response: [],
      show_modal: false,
      show_upload_excel: false,
      available_columns: [],
      select_contacts: false,
      contact_lists: [],
      selected_excel_file: null,
      phone_book_imported: false,
      excel_file_imported: false,
      schedule_message: false,
      scheduled_time: '',
      templates: [],
      selected_template: '0'
    };
  },

  methods: {
    fetchContacts() {
      this.$loader.start("main-content");
      this.$axios
        .get(`/api/v1/apps/${this.$route.params.id}/contact-lists`)
        .then((response) => {
          this.contact_lists = response.data;
          this.$loader.stop();
        });
    },

    fetchTemplates() {
      this.$loader.start("main-content");
      this.$axios
        .get(`/api/v1/apps/${this.$route.params.id}/templates`)
        .then((response) => {
          this.templates = response.data;
          this.$loader.stop();
        });
    },

    sendMessage() {
      let data = {
        recipients: this.recipients.map((row) => {
          return row.phone_number;
        }),
        message: this.message,
      };

      if(this.schedule_message) {
        data.scheduled_time = this.scheduled_time
      }

      this.$loader.start();
      this.$axios
        .post(`/api/v1/apps/${this.$route.params.id}/send-bulk-sms`, data)
        .then((response) => {
          this.response = response.data;
          this.show_modal = true;
          this.$loader.stop();
        });
    },

    importFile() {
      this.selected_excel_file = this.$refs.import_file.files[0];

      var data = new FormData();
      data.append("excel", this.selected_excel_file);

      this.$loader.start("main-content");
      this.$axios
        .post(`/api/v1/apps/${this.$route.params.id}/import-excel`, data, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.$loader.stop();
          this.recipients = response.data.phone_numbers.map((row) => {
            return { phone_number: row };
          });
          this.excel_file_imported = true;
          this.available_columns = response.data.columns;
          this.show_upload_excel = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              this.the_error = error.response.data.message;
            }
          }
        });
    },

    selectList(list) {
      this.$loader.start("main-content");
      this.$axios
        .get(`/api/v1/apps/${this.$route.params.id}/contact-lists/${list.id}`)
        .then((response) => {
          this.recipients = response.data.contacts.map((row) => {
            return { phone_number: row.phone_number };
          });
          this.$loader.stop();
          this.select_contacts = false;
          this.phone_book_imported = true;
        });
    },

    sendExcelMessages() {
      var data = new FormData();
      data.append("excel", this.selected_excel_file);
      data.append("message", this.message);

      if(this.schedule_message) {
        data.append('scheduled_time', this.scheduled_time)
      }

      this.$loader.start("main-content");
      this.$axios
        .post(`/api/v1/apps/${this.$route.params.id}/send-excel-sms`, data, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.response = response.data;
          this.show_modal = true;
          this.$loader.stop();
        });
    },
  },

  computed: {
    show_tab() {
      return this.$route.query.tab == null ? "default" : this.$route.query.tab;
    },

    message_length() {
      return this.message.length + 13
    },

    messages_count() {
      return Math.ceil((this.message.length + 13) / 160)
    }
  },

  watch: {
    show_tab() {
      this.recipients = [{}]
      this.message = ""
      this.response = []
      this.show_modal =false
      this.show_upload_excel =false
      this.available_columns =[]
      this.select_contacts =false
      this.selected_excel_file =null
      this.phone_book_imported =false
      this.excel_file_imported =false
      this.schedule_message =false
      this.scheduled_time =''
      this.selected_template = '0'
    },

    selected_template(newval) {
      if(typeof(newval) == 'object') {
        this.message = newval.content
      } else {
        this.message = ''
      }
    }
  }
};
</script>
